import { Component, Prop, VModel } from 'vue-property-decorator'
import DraggableOrig, { MoveEvent } from 'vuedraggable'
import * as tsx from 'vue-tsx-support'
import { IVmodelProps } from '@/interfaces/IVmodelProps'
import { VNode } from 'vue'
import './style.scoped.scss'

type MoveContext<T = any> = {
  component: VNode
  element: T
  index: number
  list: T[]
}

export type MovedEvent<T = any> = {
  moved?: {
    element: T
    newIndex: number
    oldIndex: number
  }
  added?: {
    element: T
    newIndex: number
  }
  removed?: {
    element: T
    oldIndex: number
  }
}

type EndDragEvent = {
  oldIndex: number
  newIndex: number
}

type StartDragEvent = {
  oldIndex: number
}

type Props = {
  'ghost-class'?: string
  handle?: string
  forceFallback?: boolean
  group?: string
  animation?: number
  disabled?: boolean

  move?: (e: MoveEvent<any>) => boolean | void

  onStart?: (e: CustomEvent & StartDragEvent) => void
  onEnd?: (e: CustomEvent & EndDragEvent) => void
  onChange?: (e: MovedEvent) => void
} & Partial<IVmodelProps<any[]>>
export const VueDraggable = tsx.ofType<Props>().convert(DraggableOrig)

@Component
export default class Draggable extends tsx.Component<
  {
    disabled?: boolean
    showDropZone?: boolean
    dropZoneHeight?: string
    group?: string
    move?: (e: MoveEvent<any>) => boolean | void
    onMoved?: (e: MovedEvent) => void
    onStart?: (e: CustomEvent & StartDragEvent) => void
    onEnd?: (e: CustomEvent & EndDragEvent) => void
    scopedSlots: {
      item: (data: { item: any; index: number }) => VNode
    }
  } & Partial<IVmodelProps<any[]>>
> {
  declare $scopedSlots: tsx.InnerScopedSlots<{
    item: { item: any; index: number }
  }>

  @VModel({ type: Array, required: true })
  array!: any[]

  @Prop({ type: Function })
  readonly move?: (e: MoveEvent<any>) => boolean | void
  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean
  @Prop({ type: String })
  readonly group?: string
  @Prop({ type: Boolean, default: false })
  readonly showDropZone!: boolean
  @Prop({ type: String, default: '50px' })
  readonly dropZoneHeight!: string

  drag = false

  onStart(e: CustomEvent & StartDragEvent) {
    this.$emit('start', e)
  }

  onEnd(e: CustomEvent & EndDragEvent) {
    this.drag = false
    this.$emit('end', e)
  }

  onMove(e: MoveEvent<MoveContext>) {
    if (this.disabled) {
      return false
    }

    if (this.move) {
      return this.move(e)
    }

    this.drag = true
  }

  protected render() {
    return (
      <VueDraggable
        value={this.array}
        onInput={value => (this.array = value)}
        ghost-class={'ghost'}
        handle=".drag-handle"
        animation={200}
        group={this.group}
        forceFallback
        class={['draggable', { disabled: this.disabled }]}
        onStart={this.onStart}
        disabled={this.disabled}
        onEnd={this.onEnd}
        onChange={e => this.$emit('moved', e)}
        move={this.onMove}
      >
        <transition-group
          tag="div"
          type="transition"
          name={!this.drag ? 'flip-list' : ''}
          class={{
            'empty-list': this.array.length === 0 && this.showDropZone,
          }}
          style={{ minHeight: this.showDropZone ? this.dropZoneHeight : 0 }}
        >
          {this.array.map((item, index) =>
            this.$scopedSlots.item({ item, index }),
          )}
        </transition-group>
      </VueDraggable>
    )
  }
}
