import { BButton } from '@/components/bootstrap'
import FadeTransition from '@/components/transitions/Fade'
import { Component, Prop } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import { KinescopeVideo, KinescopeVideoChapter } from '.'
import './style.scoped.scss'

@Component
export default class Video extends tsx.Component<{
  video: KinescopeVideo
  onSelect: (data: {
    id: string
    embed: string
    chapters: KinescopeVideoChapter[]
  }) => void
}> {
  @Prop({ type: Object, required: true })
  readonly video!: KinescopeVideo

  showPreview = false

  get durationText() {
    let seconds = Math.floor(this.video.duration % 60) || 0
    let minutes = Math.floor((this.video.duration / 60) % 60) || 0
    let hours = Math.floor((this.video.duration / (60 * 60)) % 24) || 0

    let secondsText = seconds.toString()
    let minutesText = minutes.toString()
    let hoursText = hours.toString()

    if (hours < 0) {
      hoursText = '00'
    } else if (hours < 10) {
      hoursText = `0${hours}`
    }
    if (minutes < 0) {
      minutesText = '00'
    } else if (minutes < 10) {
      minutesText = `0${minutes}`
    }
    if (seconds < 0) {
      secondsText = '00'
    } else if (seconds < 10) {
      secondsText = `0${seconds}`
    }

    return `${hoursText}:${minutesText}:${secondsText}`
  }

  select() {
    this.showPreview = false

    this.$emit('select', {
      id: this.video.id,
      embed: this.video.embed,
      chapters: this.video.chapters,
    })
  }

  protected render() {
    return (
      <div class="video d-flex align-items-center p-25">
        <img
          src={this.video.poster}
          alt=""
          class="poster mr-1 rounded border"
          onClick={e => {
            e.stopPropagation()
            this.showPreview = true
          }}
        />
        <div onClick={this.select} class="flex-grow-1">
          {this.video.title}
        </div>
        <div onClick={this.select}>{this.durationText}</div>
        <BButton
          href={`https://app.kinescope.io/video/detail/${this.video.id}`}
          target="_blank"
          variant="flat-primary"
          class="p-25"
        >
          <feather-icon icon="ExternalLinkIcon" />
        </BButton>

        <FadeTransition>
          {this.showPreview && (
            <div class="preview">
              <div class="rounded">
                <b-aspect aspect="16:9" class="mb-50">
                  <iframe
                    id="player"
                    src={this.video.embed}
                    frameborder="0"
                    width="100%"
                    height="100%"
                    //@ts-ignore
                    allow="autoplay; fullscreen; picture-in-picture; encrypted-media;"
                    allowfullscreen
                    class="rounded"
                  />
                </b-aspect>

                <BButton
                  onClick={this.select}
                  variant="primary"
                  class="w-100 mb-50"
                >
                  Выбрать видео
                </BButton>
                <BButton
                  onClick={() => (this.showPreview = false)}
                  variant={'danger'}
                  class="w-100"
                >
                  Отмена
                </BButton>
              </div>
            </div>
          )}
        </FadeTransition>
      </div>
    )
  }
}
