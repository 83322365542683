import { Component } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import { BButton, BInput, BModal, BOverlay } from '@/components/bootstrap'
import { showError } from '@/helpers/notifications'
import axios from '@/libs/axios'
import { AxiosResponse } from 'axios'
import Folder from './Folder'
import Switcher from '@/components/controls/Switcher'
import { lessonStore } from '@/store'
import Video from './Video'
import HeightTransition from '@/components/transitions/Height'

interface IKinescopeProject {
  id: string
  name: string
  folders: IKinescopeFolder[]
  privacy_type: string
  privacy_domains: unknown[]
  player_id: string
  favorite: boolean
  size: number
  items_count: number
  created_at: string
  updated_at: string | null
  encrypted: boolean
}

export interface IKinescopeFolder {
  id: string
  name: string
  project_id: string
  parent_id: string
  size: number
  items_count: number
  created_at: string
  updated_at: string | null
  deleted_at: string | null
}

export interface IKinescopeVideo {
  id: string
  project_id: string
  folder_id: string
  player_id: string
  version: number
  title: string
  subtitle: string
  description: string
  status: 'done' | unknown
  progress: number
  duration: number
  assets: {
    id: string
    video_id: string
    original_name: string
    file_size: number
    md5: string
    filetype: string
    quality: string
    resolution: string
    created_at: string
    url: string
    download_link: string
  }[]
  chapters: {
    items: {
      time: number
      title: string
    }[]
    enabled: boolean
  }
  privacy_type: string
  privacy_domains: unknown[]
  privacy_share: unknown[]
  tags: unknown[]
  poster: {
    id: string
    media_id: string
    status: 'done' | unknown
    active: boolean
    original: string
    md: string
    sm: string
    xs: string
  }
  additional_materials: unknown[]
  additional_materials_enabled: boolean
  play_link: string
  embed_link: string
  created_at: string
  updated_at: string
  subtitles: unknown[]
  subtitles_enabled: boolean
  hls_link: string
}

export type KinescopeFolder = {
  id: string
  name: string
  parentId: string | null
  itemsCount: number
  isProject: boolean
  videos: KinescopeVideo[]
  folders: KinescopeFolder[]
  isLoaded: boolean
  isOpen: boolean
}

export type KinescopeVideo = {
  id: string
  embed: string
  duration: number
  poster: string
  title: string
  chapters: KinescopeVideoChapter[]
}

export type KinescopeVideoChapter = {
  time: number
  title: string
}

@Component
export default class KinescopeVideoSelector extends tsx.Component<{
  onSelect: (data: {
    id: string
    embed: string
    chapters: KinescopeVideoChapter[]
  }) => void
}> {
  modalId = 'promocode-modal'
  pending = false
  searchQuery = ''
  searchActive = false
  videos: KinescopeVideo[] = []

  get filteredFolders() {
    if (lessonStore.showEmptyKinescopeFolders) {
      return lessonStore.kinescopeFolders
    }
    return lessonStore.kinescopeFolders.filter(folder => folder.itemsCount)
  }

  async show() {
    this.$bvModal.show(this.modalId)

    if (lessonStore.kinescopeFolders.length === 0) {
      await this.fetchProjects()
    }
  }

  async search() {
    if (!this.searchQuery) {
      showError('Введите поисковый запрос')
      return
    }

    this.pending = true
    try {
      const response: AxiosResponse<{ data: IKinescopeVideo[] }> =
        await axios.get(
          `v2/kinescope/v1/videos?page=1&per_page=100&q=${this.searchQuery}`,
        )

      this.searchActive = true

      this.videos = response.data.data.map(video => ({
        id: video.id,
        embed: video.embed_link,
        duration: video.duration,
        poster: video.poster?.xs || '',
        title: video.title,
        chapters: video.chapters.items,
      }))
    } catch (error) {
      console.error(error)
      showError('При поиске произошла ошибка')
    }
    this.pending = false
  }

  async fetchProjects() {
    this.pending = true

    try {
      const response: AxiosResponse<{ data: IKinescopeProject[] }> =
        await axios.get('v2/kinescope/v1/projects?per_page=99999')
      lessonStore.kinescopeFolders = response.data.data.map(p => ({
        id: p.id,
        itemsCount: p.items_count,
        name: p.name,
        parentId: null,
        isProject: true,
        folders: [],
        videos: [],
        isLoaded: false,
        isOpen: false,
      }))
    } catch (error) {
      console.error(error)
      showError('Произошла ошибка при обращении к api kinescope')
    }

    this.pending = false
  }

  selectVideo(data: {
    id: string
    embed: string
    chapters: KinescopeVideoChapter[]
  }) {
    this.$emit('select', data)

    this.$bvModal.hide(this.modalId)
  }

  searchReset() {
    this.searchQuery = ''
    this.searchActive = false
  }

  protected render() {
    return (
      <BModal
        id={this.modalId}
        no-close-on-backdrop
        hide-footer
        centered
        size="lg"
        title="Выберите видео"
        busy={this.pending}
      >
        <BOverlay show={this.pending} no-wrap />
        <div class="d-flex align-items-center">
          <BButton
            href="https://app.kinescope.io/"
            target="_blank"
            variant="outline-primary"
            class="mb-50 mr-50"
          >
            Перейти в Kinescope
          </BButton>

          <BButton
            onClick={this.fetchProjects}
            variant="outline-primary"
            class="mb-50"
          >
            Обновить
          </BButton>

          <div class="flex-grow-1" />

          <Switcher
            value={lessonStore.showEmptyKinescopeFolders}
            onInput={value => (lessonStore.showEmptyKinescopeFolders = value)}
          >
            Показывать пустые папки
          </Switcher>
        </div>
        <b-input-group class="mb-1">
          <BInput
            value={this.searchQuery}
            onInput={value => (this.searchQuery = value)}
            placeholder="Поиск"
            onKeydown={e => {
              if (e.key === 'Enter') {
                this.search()
              }
            }}
          />
          <b-input-group-append>
            <BButton onClick={this.search} variant="outline-primary">
              Искать
            </BButton>
          </b-input-group-append>
        </b-input-group>

        <div>
          <HeightTransition>
            {this.searchActive ? (
              <div key="1">
                {!!this.videos.length ? (
                  <div>
                    {this.videos.map(video => (
                      <Video
                        video={video}
                        key={video.id}
                        onSelect={this.selectVideo}
                      />
                    ))}
                  </div>
                ) : (
                  <div>Ничего не найдено</div>
                )}
                <BButton
                  variant="primary"
                  onClick={this.searchReset}
                  class="mt-1 w-100"
                >
                  Вернуться к просмотру папок
                </BButton>
              </div>
            ) : (
              <div key="2">
                {this.filteredFolders.map(folder => (
                  <Folder
                    folder={folder}
                    key={folder.id}
                    onSelect={this.selectVideo}
                  />
                ))}
              </div>
            )}
          </HeightTransition>
        </div>
      </BModal>
    )
  }
}
