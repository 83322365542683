import { showError } from '@/helpers/notifications'
import { Component, Prop } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import {
  IKinescopeFolder,
  IKinescopeVideo,
  KinescopeFolder,
  KinescopeVideoChapter,
} from '.'
import axios from '@/libs/axios'
import { AxiosResponse } from 'axios'
import './style.scoped.scss'
import CollapseTransition from '@/components/transitions/Collapse'
import { BOverlay } from '@/components/bootstrap'
import Video from './Video'
import { lessonStore } from '@/store'

@Component
export default class Folder extends tsx.Component<{
  folder: KinescopeFolder
  onSelect: (data: {
    id: string
    embed: string
    chapters: KinescopeVideoChapter[]
  }) => void
}> {
  @Prop({ type: Object, required: true })
  readonly folder!: KinescopeFolder
  pending = false

  get filteredFolders() {
    if (lessonStore.showEmptyKinescopeFolders) {
      return this.folder.folders
    }
    return this.folder.folders.filter(folder => folder.itemsCount)
  }

  async folderClick() {
    if (this.pending) {
      return
    }

    if (this.folder.isProject && !this.folder.isLoaded) {
      await this.fetchFolders()
    } else if (this.folder.folders.length || this.folder.itemsCount) {
      this.folder.isOpen = !this.folder.isOpen
    }
  }

  async fetchFolders() {
    this.pending = true
    try {
      const response: AxiosResponse<{ data: IKinescopeFolder[] }> =
        await axios.get(`v2/kinescope/v1/projects/${this.folder.id}/folders`)

      const videos = await this.fetchVideos()

      this.folder.videos = videos
        .filter(video => video.folder_id === null)
        .map(video => ({
          id: video.id,
          embed: video.embed_link,
          duration: video.duration,
          poster: video.poster?.xs || '',
          title: video.title,
          chapters: video.chapters.items,
        }))

      this.folder.folders = this.createFolders(
        response.data.data,
        videos,
        this.folder.id,
      )

      this.folder.isLoaded = true
      this.$nextTick(this.folderClick)
    } catch (error) {
      showError('Произошла ошибка при загрузке папок')
      console.error(error)
    }
    this.pending = false
  }

  async fetchVideos() {
    try {
      const response: AxiosResponse<{ data: IKinescopeVideo[] }> =
        await axios.get(
          `v2/kinescope/v1/videos?per_page=99999&project_id=${this.folder.id}`,
        )

      return response.data.data
    } catch (error) {
      showError('При загрузке роликов произошла ошибка')
      console.error(error)
      return []
    }
  }

  createFolders(
    folders: IKinescopeFolder[],
    videos: IKinescopeVideo[],
    id: string,
  ): KinescopeFolder[] {
    return folders
      .filter(folder => folder.parent_id === id)
      .map(folder => ({
        id: folder.id,
        parentId: folder.parent_id,
        name: folder.name,
        isProject: false,
        itemsCount: folder.items_count,
        videos: videos
          .filter(video => video.folder_id === folder.id)
          .map(video => ({
            id: video.id,
            embed: video.embed_link,
            duration: video.duration,
            poster: video.poster?.xs || '',
            title: video.title,
            chapters: video.chapters.items,
          })),
        folders: this.createFolders(folders, videos, folder.id),
        isLoaded: true,
        isOpen: false,
      }))
  }

  protected render() {
    return (
      <BOverlay show={this.pending}>
        <div class="folder">
          <div
            onClick={this.folderClick}
            class={[
              'folder-header',
              'd-flex',
              'align-items-center',
              'py-25',
              'px-50',
              'rounded',
              { 'has-items': this.folder.itemsCount },
            ]}
          >
            <feather-icon
              icon={this.folder.isOpen ? 'FolderMinusIcon' : 'FolderIcon'}
              class="mr-1"
            />
            <div class="flex-grow-1">{this.folder.name}</div>
            {!!this.folder.itemsCount && <div>{this.folder.itemsCount}</div>}
            {!!this.folder.itemsCount && (
              <feather-icon icon="FilmIcon" class="ml-25" />
            )}
            {!this.folder.itemsCount && 'нет видео'}
          </div>
          <CollapseTransition hidden>
            {this.folder.isOpen && (
              <div class="ml-1">
                {this.filteredFolders.map(folder => (
                  <Folder
                    folder={folder}
                    key={folder.id}
                    onSelect={e => this.$emit('select', e)}
                  />
                ))}
                {this.folder.videos.map(video => (
                  <Video
                    video={video}
                    key={video.id}
                    onSelect={e => this.$emit('select', e)}
                  />
                ))}
              </div>
            )}
          </CollapseTransition>
        </div>
      </BOverlay>
    )
  }
}
