import { BButton } from '@/components/bootstrap'
import FormInput from '@/components/controls/form/FormInput'
import { lessonStore } from '@/store'
import { VideoChapter } from '@/store/lessonStore'
import { Component, Prop } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import './style.scoped.scss'

@Component
export default class Chapter extends tsx.Component<{
  chapter: VideoChapter
}> {
  @Prop({ type: Object, required: true })
  readonly chapter!: VideoChapter

  protected render() {
    return (
      <div class="d-flex border rounded p-25 mb-25">
        <div class="drag-handle drag-handle-styles" />
        <FormInput
          fieldValidator={this.chapter.time}
          placeholder="Время"
          v-mask={'##:##:##'}
          style={{ width: '110px' }}
          class="m-0"
        />
        <FormInput
          fieldValidator={this.chapter.title}
          placeholder="Заголовок"
          class="flex-grow-1 mx-50 mb-0"
        />
        <div>
          <BButton
            onClick={() => lessonStore.removeChapter(this.chapter)}
            variant="flat-danger"
            class="px-50"
          >
            <feather-icon icon="TrashIcon" />
          </BButton>
        </div>
      </div>
    )
  }
}
