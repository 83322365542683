import { BButton, BCheckbox, BRadio } from '@/components/bootstrap'
import FormInput from '@/components/controls/form/FormInput'
import CollapseTransition from '@/components/transitions/Collapse'
import { showError } from '@/helpers/notifications'
import { lessonStore } from '@/store'
import { TestAnswer, TestQuestion } from '@/store/lessonStore'
import { Component, Prop, Watch } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import TestImageLoader from './ImageLoader'
import './style.scoped.scss'

@Component
export default class Answer extends tsx.Component<{
  index: number
  answer: TestAnswer
  question: TestQuestion
}> {
  @Prop({ type: Number, required: true })
  readonly index!: number
  @Prop({ type: Object, required: true })
  readonly answer!: TestAnswer
  @Prop({ type: Object, required: true })
  readonly question!: TestQuestion

  @Watch('answer.isCorrect')
  onIsCorrectChanged() {
    this.question.answersCountValidator.reset()
  }

  onRadioClick(e: Event) {
    e.preventDefault()
    this.answer.isCorrect = !this.answer.isCorrect

    this.question.answers
      .filter(answer => answer !== this.answer)
      .forEach(answer => (answer.isCorrect = false))
  }

  remove() {
    if (this.question.answers.length === 2) {
      showError('В вопросе не может быть меньше двух ответов')
      return
    }

    lessonStore.removeAnswer({ question: this.question, answer: this.answer })
  }

  protected render() {
    return (
      <div
        class={[
          'answer',
          'rounded',
          'mb-50',
          'p-25',
          'shadow-sm',
          'd-flex',
          { correct: this.answer.isCorrect },
        ]}
      >
        <div class="d-flex align-items-center drag-handle">
          <BButton
            onClick={this.remove}
            variant="flat-danger"
            class="delete-answer-button"
          >
            <feather-icon icon="TrashIcon" />
          </BButton>
          <span class="answer-index">{`#${this.index}`}</span>
          {this.question.multiply ? (
            <BCheckbox
              checked={this.answer.isCorrect}
              onInput={value => (this.answer.isCorrect = value)}
              class="mx-50"
            />
          ) : (
            <BRadio
              value={true}
              checked={this.answer.isCorrect}
              nativeOn={{
                click: this.onRadioClick,
              }}
              class="mx-50"
            />
          )}
        </div>
        <div
          class={[
            'd-flex',
            'flex-grow-1',
            { 'flex-column': !this.question.isImages },
          ]}
        >
          {this.question.isImages ? (
            <TestImageLoader answer={this.answer} class="mr-1" />
          ) : (
            <FormInput
              label="Ответ:"
              fieldValidator={this.answer.title}
              textarea
              class="mb-50"
            />
          )}
          <CollapseTransition hidden>
            {!this.question.multiply && !this.answer.isCorrect && (
              <div class="flex-grow-1">
                <FormInput
                  label="Примечание к неверному ответу:"
                  value={this.answer.note}
                  onInput={value => (this.answer.note = value)}
                  textarea
                  class="m-0"
                  rows={this.question.isImages ? 3 : 2}
                />
              </div>
            )}
          </CollapseTransition>
        </div>
      </div>
    )
  }
}
