import { BButton, BCard, BCol, BFormFile, BRow } from '@/components/bootstrap'
import QuillEditor from '@/components/controls/QuillEditor'
import { showError } from '@/helpers/notifications'
import { lessonStore } from '@/store'
import { Component, Ref } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import FileView from './FileView'
import LinkView from './LinkView'

@Component
export default class FilesTab extends tsx.Component<{}> {
  @Ref() readonly fileInput!: HTMLInputElement

  handleFileUpload(file: File) {
    if (!file) {
      return
    }

    if (lessonStore.files.some(f => f.file?.name === file.name)) {
      showError('Такой файл уже добавлен')
      //@ts-ignore
      this.fileInput.files = []
      return
    }
    lessonStore.addFile({ file })
    //@ts-ignore
    this.fileInput.files = []
  }

  addLink() {
    lessonStore.addLink({})
  }

  protected render() {
    return (
      <div>
        <BCard title="Примечание к занятию">
          <QuillEditor
            value={lessonStore.note.value}
            onInput={lessonStore.note.set}
          />
        </BCard>
        <BCard title="Файлы">
          <BRow>
            <BCol sm={10} md={8} xl={6}>
              <BFormFile
                ref="fileInput"
                onInput={this.handleFileUpload}
                browse-text="Выбор"
                placeholder="Выберите файл для загрузки"
                drop-placeholder="Перетащите сюда..."
                accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .zip"
                class="mb-1"
              />
              {lessonStore.files.map(file => (
                <FileView file={file} />
              ))}
            </BCol>
          </BRow>
        </BCard>
        <BCard title="Ссылки">
          <BRow>
            <BCol sm={10} md={8} xl={6}>
              {lessonStore.links.map(link => (
                <LinkView link={link} />
              ))}
            </BCol>
          </BRow>
          <BButton onClick={this.addLink} variant="outline-primary">
            Добавить ссылку
          </BButton>
        </BCard>
      </div>
    )
  }
}
