import {
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormRadioGroup,
  BRow,
} from '@/components/bootstrap'
import DateTimePicker from '@/components/controls/DateTimePicker'
import FormInput from '@/components/controls/form/FormInput'
import Select from '@/components/controls/Select'
import UserSelectTemplate from '@/components/controls/Select/templates/User'
import Switcher from '@/components/controls/Switcher'
import Link from '@/components/Link'
import CollapseTransition from '@/components/transitions/Collapse'
import HeightTransition from '@/components/transitions/Height'
import { courseStore, lessonStore } from '@/store'
import { Teacher } from '@/store/courseStore'
import { HomeworkType } from '@/store/lessonStore'
import { Component, Ref, Watch } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import ChaptersEditor from '../ChaptersEditor'
import KinescopeVideoSelector, {
  KinescopeVideoChapter,
} from '../KinescopeVideoSelector'

type LessonAccess = {
  url: string
  label: string
}

@Component
export default class MainTab extends tsx.Component<{}> {
  @Ref() readonly kinescopeVideoSelector!: KinescopeVideoSelector

  homeworkTypes: { text: string; value: HomeworkType }[] = [
    {
      text: 'Домашнее задание',
      value: 'work',
    },
    {
      text: 'Тестирование',
      value: 'test',
    },
    {
      text: 'Отсутствует',
      value: 'none',
    },
  ]

  videoAccessLesson: LessonAccess | true | null = null
  homeworkAccessLesson: LessonAccess | true | null = null

  get selectedSprint() {
    return lessonStore.selectedSprint.value
  }

  get isLive() {
    return lessonStore.isLive
  }

  get kinescopeId() {
    return lessonStore.kinescopeVideoId.value
  }

  @Watch('selectedSprint')
  onSprintChanged() {
    this.onVideoAccessChanged(lessonStore.videoAccessStep.value)
    this.onHomeworkAccessChanged(lessonStore.homeworkAccessStep.value)
  }

  @Watch('isLive')
  onIsLiveChanged() {
    lessonStore.liveUrl.reset()
  }

  @Watch('kinescopeId')
  onKinescopeIdChanged() {
    if (this.kinescopeId.length === 36) {
      lessonStore.fetchChapters(this.kinescopeId)
    }
    if (!this.kinescopeId) {
      lessonStore.fillChapters([])
      lessonStore.kinescopeEmbed = ''
    }
  }

  mounted() {
    this.onVideoAccessChanged(lessonStore.videoAccessStep.value)
    this.onHomeworkAccessChanged(lessonStore.homeworkAccessStep.value)
  }

  onVideoAccessChanged(value: number | null) {
    if (!value) {
      return (this.videoAccessLesson = null)
    }
    this.videoAccessLesson = this.updateAccessLesson(value)
  }

  onHomeworkAccessChanged(value: number | null) {
    if (!value) {
      return (this.homeworkAccessLesson = null)
    }
    this.homeworkAccessLesson = this.updateAccessLesson(value)
  }

  updateAccessLesson(offset: number): LessonAccess | true | null {
    if (this.selectedSprint === null) {
      return null
    }

    const lesson = this.getLesson(offset)

    if (lesson) {
      const streamId = this.$route.params.streamId

      return {
        url: `/streams/${streamId}/lessons/edit/${lesson.id}`,
        label: `${lesson.positionText} ${lesson.title}`,
      }
    } else {
      return true
    }
  }

  getLesson(offset: number) {
    let position = 0
    const sprintId = this.selectedSprint!.id

    if (
      lessonStore.lessonState === 'new' ||
      lessonStore.selectedSprint.value?.id !== lessonStore.lessonSprintId
    ) {
      position =
        lessonStore.lessons.indexOf(
          lessonStore.lessons.filter(l => l.sprintId === sprintId).pop()!,
        ) + 1
    } else {
      position = lessonStore.lessons.findIndex(
        l => l.id === lessonStore.lessonId,
      )
    }

    return lessonStore.lessons[position - offset]
  }

  selectVideo({
    id,
    embed,
    chapters,
  }: {
    id: string
    embed: string
    chapters: KinescopeVideoChapter[]
  }) {
    lessonStore.kinescopeVideoId.set(id)
    lessonStore.kinescopeEmbed = embed
    lessonStore.fillChapters(chapters)
  }

  protected render() {
    return (
      <BRow>
        <BCol md={6}>
          <BCard title="Информация">
            <FormInput
              label="Название занятия"
              fieldValidator={lessonStore.title}
            />
            <FormInput
              label="Описание занятия"
              fieldValidator={lessonStore.description}
              textarea
              rows={4}
            />
            <BFormGroup
              label="Спринт"
              state={lessonStore.selectedSprint.hasError ? false : null}
              invalid-feedback={lessonStore.selectedSprint.errorText}
            >
              <Select
                value={lessonStore.selectedSprint.value}
                onInput={lessonStore.selectedSprint.set}
                options={lessonStore.sprints}
                state={lessonStore.selectedSprint.hasError ? false : null}
                placeholder="Выберите спринт"
                label="title"
                clearable={false}
              />
            </BFormGroup>
            <BFormGroup label="Вид задания">
              <BFormRadioGroup
                checked={lessonStore.homeworkType}
                onchange={value => (lessonStore.homeworkType = value)}
                options={this.homeworkTypes}
              />
            </BFormGroup>
            <FormInput
              label="SendPulse-событие"
              fieldValidator={lessonStore.sendpulseEvent}
            />
          </BCard>

          <BCard title="Преподаватель">
            <Select
              value={lessonStore.selectedTeacher.value}
              onInput={lessonStore.selectedTeacher.set}
              options={courseStore.teachers}
              label="fullName"
              placeholder="Выберите преподавателя"
              state={lessonStore.selectedTeacher.hasError ? false : null}
              scopedSlots={{
                selectedOption: (option: Teacher) => (
                  <UserSelectTemplate
                    name={option.fullName}
                    avatar={option.avatar_url}
                    size="sm"
                  />
                ),
                option: ({ option }: { option: Teacher }) => (
                  <UserSelectTemplate
                    name={option.fullName}
                    avatar={option.avatar_url}
                  />
                ),
              }}
            />
            <small class="text-danger">
              {lessonStore.selectedTeacher.errorText}
            </small>
          </BCard>

          <BCard title="Доступ">
            <BFormGroup
              label="Время старта (киев)"
              state={lessonStore.startDate.hasError ? false : null}
              invalid-feedback={lessonStore.startDate.errorText}
            >
              <DateTimePicker
                value={lessonStore.startDate.value}
                onInput={lessonStore.startDate.set}
                enableTime
                dateFormat="Y-m-d H:i:S"
                displayedFormat="l d M Y H:i"
              />
            </BFormGroup>
            <BRow>
              <BCol cols={5}>
                <FormInput
                  label="Шаг доступа к видео"
                  fieldValidator={lessonStore.videoAccessStep}
                  onInput={this.onVideoAccessChanged}
                  type="number"
                />
              </BCol>
              <BCol cols={7}>
                <BFormGroup label="Урок">
                  <div class="mt-50">
                    {this.videoAccessLesson === true ? (
                      'Урок доступен всегда'
                    ) : this.videoAccessLesson === null ? (
                      'Выберите шаг'
                    ) : (
                      <Link to={this.videoAccessLesson.url}>
                        {this.videoAccessLesson.label}
                      </Link>
                    )}
                  </div>
                </BFormGroup>
              </BCol>
            </BRow>
            <BRow>
              <BCol cols={5}>
                <FormInput
                  label="Шаг доступа к дз"
                  fieldValidator={lessonStore.homeworkAccessStep}
                  onInput={this.onHomeworkAccessChanged}
                  type="number"
                />
              </BCol>
              <BCol cols={7}>
                <BFormGroup label="Урок">
                  <div class="mt-50">
                    {this.homeworkAccessLesson === true ? (
                      'Урок доступен всегда'
                    ) : this.homeworkAccessLesson === null ? (
                      'Выберите шаг'
                    ) : (
                      <Link to={this.homeworkAccessLesson.url}>
                        {this.homeworkAccessLesson.label}
                      </Link>
                    )}
                  </div>
                </BFormGroup>
              </BCol>
            </BRow>
          </BCard>
        </BCol>

        <BCol md={6}>
          <BCard title="Видео">
            <Switcher
              value={lessonStore.isChatEnabled}
              onInput={value => (lessonStore.isChatEnabled = value)}
            >
              Показывать чат
            </Switcher>
            <Switcher
              value={lessonStore.isLive}
              onInput={value => (lessonStore.isLive = value)}
            >
              Live-занятие
            </Switcher>

            <div class="my-1">
              <HeightTransition>
                {this.isLive ? (
                  <div key="1">
                    <FormInput
                      label="Ссылка на youtube-трансляцию"
                      fieldValidator={lessonStore.liveUrl}
                      class="m-0"
                    />
                  </div>
                ) : (
                  <div key="2">
                    <CollapseTransition hidden>
                      {lessonStore.kinescopeEmbed && (
                        <b-aspect aspect="16:9" class="mb-50">
                          <iframe
                            id="player"
                            src={lessonStore.kinescopeEmbed}
                            frameborder="0"
                            width="100%"
                            height="100%"
                            //@ts-ignore
                            allow="autoplay; fullscreen; picture-in-picture; encrypted-media;"
                            allowfullscreen
                            class="rounded"
                          />
                        </b-aspect>
                      )}
                    </CollapseTransition>
                    <FormInput
                      label="Id видео kinescope"
                      fieldValidator={lessonStore.kinescopeVideoId}
                      scopedSlots={{
                        append: () => (
                          <BButton
                            onClick={() => this.kinescopeVideoSelector.show()}
                            variant="outline-primary"
                          >
                            Выбор
                          </BButton>
                        ),
                      }}
                    />
                    <FormInput
                      label="Ссылка на альтернативное youtube-видео"
                      fieldValidator={lessonStore.alternativeVideoUrl}
                    />
                    <ChaptersEditor />
                  </div>
                )}
              </HeightTransition>
            </div>
          </BCard>
        </BCol>

        <KinescopeVideoSelector
          ref="kinescopeVideoSelector"
          onSelect={this.selectVideo}
        />
      </BRow>
    )
  }
}
