import { BCard, BCardTitle } from '@/components/bootstrap'
import FormInput from '@/components/controls/form/FormInput'
import QuillEditor from '@/components/controls/QuillEditor'
import { lessonStore } from '@/store'
import { Component } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'

@Component
export default class HomeworkTab extends tsx.Component<{}> {
  protected render() {
    return (
      <div>
        <BCard title="Баллы за сдачу дз">
          <FormInput
            fieldValidator={lessonStore.homeworkPoints}
            type="number"
            class="mb-2"
            style={{ maxWidth: '300px' }}
          />

          <BCardTitle>Текст задания</BCardTitle>
          <QuillEditor
            value={lessonStore.homeworkText.value}
            onInput={lessonStore.homeworkText.set}
            state={lessonStore.homeworkText.hasError ? false : null}
          />
          <small class="text-danger">
            {lessonStore.homeworkText.errorText}
          </small>
        </BCard>
      </div>
    )
  }
}
