import { BButton } from '@/components/bootstrap'
import FormInput from '@/components/controls/form/FormInput'
import Switcher from '@/components/controls/Switcher'
import Draggable from '@/components/Draggable'
import CollapseTransition from '@/components/transitions/Collapse'
import { showError } from '@/helpers/notifications'
import { lessonStore } from '@/store'
import { TestQuestion } from '@/store/lessonStore'
import { Component, Prop, Watch } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import Answer from './Answer'
import TestImageLoader from './ImageLoader'
import './style.scoped.scss'

@Component
export default class Question extends tsx.Component<{
  index: number
  question: TestQuestion
}> {
  @Prop({ type: Number, required: true })
  readonly index!: number
  @Prop({ type: Object, required: true })
  readonly question!: TestQuestion

  // collapsed = false

  get hasError() {
    return (
      this.question.title.hasError ||
      this.question.answers.some(answer => answer.title.hasError) ||
      this.question.answers.some(answer => answer.image.hasError) ||
      this.question.answersCountValidator.hasError
    )
  }

  @Watch('question.multiply')
  onMultiplyChanged() {
    if (!this.question.multiply) {
      this.question.answers.forEach(answer => (answer.isCorrect = false))
      this.question.answersCountValidator.reset()
    }
  }

  @Watch('question.isImages')
  onIsImagesChanged() {
    this.question.answers.forEach(answer => answer.title.reset())
    this.question.answers.forEach(answer => answer.image.reset())
  }

  removeQuestion(e: Event) {
    e.stopPropagation()

    if (lessonStore.testQuestions.length === 1) {
      showError('В тесте должен быть как минимум один вопрос')
      return
    }

    lessonStore.removeQuestion(this.question)
  }

  shuffleAnswers() {
    this.question.answers.sort(() => Math.random() - 0.5)
  }

  protected render() {
    return (
      <div
        class={[
          'question',
          'rounded',
          'mb-1',
          'shadow',
          { error: this.hasError },
        ]}
      >
        <div
          onClick={() => (this.question.collapsed = !this.question.collapsed)}
          class="d-flex align-items-center"
        >
          <BButton variant="flat-secondary" class="p-50">
            <feather-icon
              icon="ChevronUpIcon"
              class={['transition', { 'rotate-180': this.question.collapsed }]}
            />
          </BButton>
          <span class="drag-handle flex-grow-1 px-50 py-25">{`#${this.index}. ${this.question.title.value}`}</span>
          <BButton
            variant="flat-danger"
            onClick={this.removeQuestion}
            class="p-50"
          >
            <feather-icon icon="TrashIcon" />
          </BButton>
        </div>
        <CollapseTransition hidden>
          {!this.question.collapsed && (
            <div class="border-top">
              <div class="p-50">
                <div class="d-flex">
                  <FormInput
                    label="Вопрос:"
                    fieldValidator={this.question.title}
                    textarea
                    rows={3}
                    class="flex-grow-1 mr-50"
                  />
                  <TestImageLoader question={this.question} />
                </div>

                <div class="d-flex flex-wrap align-items-center">
                  <div class="flex-grow-1">Ответы:</div>
                  <BButton
                    onClick={this.shuffleAnswers}
                    variant="flat-secondary"
                    class="p-50 mr-50"
                  >
                    <small class="mr-50">Перемешать ответы</small>
                    <feather-icon icon="RepeatIcon" />
                  </BButton>

                  <label
                    class="m-0 mr-50"
                    for={`multiply-check-${this.question.id}`}
                  >
                    множественный выбор
                  </label>
                  <Switcher
                    switcherId={`multiply-check-${this.question.id}`}
                    value={this.question.multiply}
                    onInput={value => {
                      this.question.multiply = value
                    }}
                    scopedSlots={{
                      leftIcon: () => <feather-icon icon="CheckIcon" />,
                      rightIcon: () => <feather-icon icon="XIcon" />,
                    }}
                  />

                  <label
                    class="m-0 mx-50"
                    for={`questions-images-check-${this.question.id}`}
                  >
                    текст / изображения
                  </label>
                  <Switcher
                    switcherId={`questions-images-check-${this.question.id}`}
                    value={this.question.isImages}
                    onInput={value => {
                      this.question.isImages = value
                    }}
                    scopedSlots={{
                      leftIcon: () => <feather-icon icon="ImageIcon" />,
                      rightIcon: () => <feather-icon icon="TypeIcon" />,
                    }}
                  />
                </div>

                <Draggable
                  value={this.question.answers}
                  onInput={value => (this.question.answers = value)}
                  scopedSlots={{
                    item: ({ item, index }) => (
                      <Answer
                        key={item.id}
                        answer={item}
                        question={this.question}
                        index={index + 1}
                      />
                    ),
                  }}
                />

                {this.question.answersCountValidator.hasError && (
                  <small class="text-danger d-block mb-1">
                    {this.question.answersCountValidator.errorText}
                  </small>
                )}
                <BButton
                  onClick={() =>
                    lessonStore.addAnswer({ question: this.question })
                  }
                  variant="outline-primary"
                >
                  Добавить ответ
                </BButton>
              </div>
            </div>
          )}
        </CollapseTransition>
      </div>
    )
  }
}
