import { Component, Prop } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'

@Component
export default class HeightTransition extends tsx.Component<{
  duration?: number
}> {
  @Prop({ type: Number, default: 0.3 })
  readonly duration!: number

  lastTransitionStartedAt = 0

  get halfDuration() {
    return this.duration / 2
  }

  onBeforeLeave(el: Element) {
    this.lastTransitionStartedAt = new Date().getTime()

    const rect = el.getBoundingClientRect()
    const parent = el.parentElement as HTMLElement
    parent.style.height = `${rect.height}px`
  }

  onBeforeEnter(el: Element) {
    const htmlEl = el as HTMLElement
    htmlEl.style.transition = `${this.halfDuration}s all ease`
    htmlEl.style.opacity = '0'
  }

  async onEnter(el: Element, done: () => void) {
    const rect = el.getBoundingClientRect()
    const parent = el.parentElement as HTMLElement
    parent.style.transition = `${this.duration}s all ease`
    parent.style.height = `${rect.height}px`
    parent.style.overflow = 'hidden'

    const htmlEl = el as HTMLElement

    setTimeout(() => {
      htmlEl.style.opacity = '1'
    }, this.halfDuration * 1000)

    setTimeout(() => {
      done()

      if (
        new Date().getTime() - this.lastTransitionStartedAt >=
        this.duration * 1000
      ) {
        htmlEl.style.removeProperty('transition')
        htmlEl.style.removeProperty('opacity')
        parent.style.removeProperty('height')
        parent.style.removeProperty('transition')
        parent.style.removeProperty('overflow')
      }
    }, this.duration * 1000)
  }

  async onLeave(el: Element, done: () => void) {
    const htmlEl = el as HTMLElement
    htmlEl.style.transition = `${this.halfDuration}s all ease`
    htmlEl.style.opacity = '0'

    setTimeout(() => {
      htmlEl.style.display = 'none'
    }, this.halfDuration * 1000)

    setTimeout(done, this.duration * 1000)
  }

  protected render() {
    return (
      <transition
        css={false}
        onBeforeLeave={this.onBeforeLeave}
        onBeforeEnter={this.onBeforeEnter}
        onEnter={this.onEnter}
        onLeave={this.onLeave}
      >
        {this.$slots.default}
      </transition>
    )
  }
}
