import { BButton } from '@/components/bootstrap'
import FadeTransition from '@/components/transitions/Fade'
import { FieldValidator } from '@/helpers/validation'
import { TestAnswer, TestQuestion } from '@/store/lessonStore'
import { Component, Prop, Ref } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import './style.scoped.scss'

@Component
export default class TestImageLoader extends tsx.Component<
  (
    | {
        question: TestQuestion
        answer?: never
      }
    | {
        question?: never
        answer: TestAnswer
      }
  ) & {
    state?: boolean | null
  }
> {
  @Ref() imageInput!: HTMLInputElement

  @Prop({ type: Object })
  readonly question?: TestQuestion
  @Prop({ type: Object })
  readonly answer?: TestAnswer
  @Prop({ type: Boolean, default: null })
  readonly state!: boolean | null

  showPreview = false

  get uploadTarget() {
    return this.question || this.answer!
  }

  get image() {
    if (this.uploadTarget.image instanceof FieldValidator) {
      return this.uploadTarget.image.value
    } else {
      return this.uploadTarget.image
    }
  }
  set image(value: string | null) {
    if (this.uploadTarget.image instanceof FieldValidator) {
      this.uploadTarget.image.set(value)
    } else {
      this.uploadTarget.image = value
    }
  }

  get hasError() {
    if (this.uploadTarget.image instanceof FieldValidator) {
      return this.uploadTarget.image.hasError
    }
    return false
  }

  get errorText() {
    if (this.uploadTarget.image instanceof FieldValidator) {
      return this.uploadTarget.image.errorText
    }
    return null
  }

  onUploadClick() {
    this.imageInput.click()
  }

  handleImageUpload() {
    this.$nextTick(() => {
      const file = this.imageInput.files![0]
      if (!file) {
        return
      }
      this.uploadTarget.imageFile = file
      this.setImagePreview(file)
      this.imageInput.value = ''
    })
  }

  setImagePreview(file: File) {
    if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => (this.image = reader.result!.toString()),
        false,
      )
      reader.readAsDataURL(file)
    }
  }

  clear() {
    this.uploadTarget.imageFile = null
    this.image = null
  }

  zoom() {
    if (this.uploadTarget.image) {
      this.showPreview = true
    }
  }

  protected render() {
    return (
      <div class="image-wrapper">
        <FadeTransition>
          {this.image && this.showPreview && (
            <div class="zoom-image" onClick={() => (this.showPreview = false)}>
              <img src={this.image} alt="" />
            </div>
          )}
        </FadeTransition>

        <input
          ref="imageInput"
          type="file"
          class="hidden"
          accept=".jpg, .jpeg, .png"
          onInput={this.handleImageUpload}
        />

        <label class="mr-50">Изображение:</label>
        <BButton onClick={this.zoom} variant="flat-secondary" class="p-0 mx-50">
          <feather-icon icon="ZoomInIcon" />
        </BButton>
        <BButton onClick={this.clear} variant="flat-secondary" class="p-0">
          <feather-icon icon="TrashIcon" />
        </BButton>

        <div
          class={[
            'image-container',
            'd-flex',
            'align-items-center',
            'justify-content-center',
            { error: this.hasError },
          ]}
        >
          {this.image ? (
            <img class="image-preview" src={this.image} alt="" />
          ) : (
            <feather-icon size="40" icon="UploadIcon" />
          )}
          <div
            class="image-upload d-flex align-items-center justify-content-center"
            onClick={this.onUploadClick}
          >
            <feather-icon size="40" icon="UploadIcon" />
          </div>
        </div>
        {this.hasError && <small class="text-danger">{this.errorText}</small>}
      </div>
    )
  }
}
