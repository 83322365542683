import { BButton } from '@/components/bootstrap'
import FormInput from '@/components/controls/form/FormInput'
import { lessonStore } from '@/store'
import { LessonFile } from '@/store/lessonStore'
import { Component, Prop } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'

@Component
export default class FileView extends tsx.Component<{
  file: LessonFile
}> {
  @Prop({ type: Object, required: true })
  readonly file!: LessonFile

  remove() {
    lessonStore.removeFile(this.file)
  }

  protected render() {
    return (
      <div class="d-flex align-items-start">
        <FormInput
          fieldValidator={this.file.name}
          placeholder="Имя файла"
          class="mr-50 mb-50 flex-grow-1"
        />
        {this.file.url && (
          <BButton
            href={this.file.url}
            target="_blank"
            variant="outline-primary"
            class="mr-50"
          >
            <feather-icon icon="DownloadIcon" />
          </BButton>
        )}
        <BButton onClick={this.remove} variant="outline-danger">
          <feather-icon icon="TrashIcon" />
        </BButton>
      </div>
    )
  }
}
