import { BButton, BOverlay } from '@/components/bootstrap'
import Draggable from '@/components/Draggable'
import { lessonStore } from '@/store'
import { Component } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import Chapter from './Chapter'
import './style.scoped.scss'

@Component
export default class ChaptersEditor extends tsx.Component<{}> {
  protected render() {
    return (
      <div>
        <div class="mb-50">Таймкоды</div>
        <BOverlay show={lessonStore.chaptersPending}>
          <Draggable
            value={lessonStore.chapters}
            onInput={value => (lessonStore.chapters = value)}
            scopedSlots={{
              item: ({ item }) => <Chapter key={item.id} chapter={item} />,
            }}
          />

          <BButton
            variant="outline-primary"
            onClick={() => lessonStore.addChapter()}
            class="w-100"
          >
            Добавить таймкод
          </BButton>
        </BOverlay>
      </div>
    )
  }
}
