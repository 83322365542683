import { BButton, BTab, BTabs } from '@/components/bootstrap'
import { Component, Ref, Watch } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import MainTab from '@/components/lessonEdit/tabs/Main'
import FilesTab from '@/components/lessonEdit/tabs/Files'
import HomeworkTab from '@/components/lessonEdit/tabs/Homework'
import TestTab from '@/components/lessonEdit/tabs/Test'
import { lessonStore } from '@/store'
import { showError, showMessage } from '@/helpers/notifications'
import LoaderOverlay, { Task } from '@/components/LoaderOverlay'
import axios from '@/libs/axios'
import { AxiosResponse } from 'axios'
import { copyTextToClipboard } from '@/helpers/copy'

@Component
export default class LessonEditPage extends tsx.Component<{}> {
  @Ref() readonly overlay!: LoaderOverlay

  get homeworkType() {
    return lessonStore.homeworkType
  }

  @Watch('homeworkType')
  onHomeworkTypeChanged() {
    if (this.homeworkType !== 'work') {
      lessonStore.homeworkValidator.resetErrors()
    }
    if (this.homeworkType !== 'test') {
      lessonStore.testValidator.resetErrors()
    }
  }

  async mounted() {
    if (lessonStore.testQuestions.length === 0) {
      lessonStore.addQuestion()
    }

    if (lessonStore.lessonState === 'new') {
      const sprintId = parseInt(this.$route.query.sprintId as string)
      if (sprintId) {
        lessonStore.selectedSprint.set(
          lessonStore.sprints.find(sprint => sprint.id === sprintId) || null,
        )
      }
    }
  }

  private async copy() {
    copyTextToClipboard(JSON.stringify(await lessonStore.getLessonData()))
    showMessage('Скопировано!')
  }

  private async paste() {
    await lessonStore.pasteLesson(await navigator.clipboard.readText())
  }

  async saveLesson(close = true) {
    let result = await lessonStore.validate()

    if (!result) {
      showError('При заполнении полей допущены ошибки')
      return
    }

    result = await this.overlay.show(this.getLoadingTasks())

    if (!result) {
      return
    }

    if (close) {
      this.$router.push(
        `/streams/${this.$route.params.streamId}?sprintId=${
          lessonStore.selectedSprint.value!.id
        }`,
      )
    } else if (lessonStore.lessonState === 'new') {
      history.replaceState(
        null,
        '',
        location.href.replace(
          'lessons/add',
          `lessons/edit/${lessonStore.lessonId}`,
        ),
      )
    }
  }

  getLoadingTasks(): Task[] {
    const tasks: Task[] = []

    if (lessonStore.kinescopeVideoId) {
      tasks.push({
        title: 'Сохранение таймкодов в Kinesope',
        action: lessonStore.updateChapters,
      })
    }

    for (const file of lessonStore.files.filter(f => f.file)) {
      tasks.push({
        title: `Загрузка файла ${file.file!.name}`,
        action: async () => {
          try {
            const formData = new FormData()
            formData.append('file', file.file!)

            const response: AxiosResponse<{ url: string }> = await axios.post(
              'v1/uploads/other',
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              },
            )

            file.file = null
            file.url = response.data.url
            return true
          } catch (error) {
            console.error(error)
            showError('При загрузке файла произошла ошибка')
            return false
          }
        },
      })
    }

    for (const question of lessonStore.testQuestions) {
      if (question.imageFile) {
        tasks.push({
          title: `Загрузка изображения ${question.imageFile.name}`,
          action: async () => {
            try {
              const formData = new FormData()
              formData.append('image', question.imageFile!)
              formData.append('folder', 'lessons/tests')

              const response: AxiosResponse<{ url: string }> = await axios.post(
                'v2/uploads/images',
                formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                },
              )

              question.imageFile = null
              question.image = response.data.url
              return true
            } catch (error) {
              console.error(error)
              showError('При загрузке изображения произошла ошибка')
              return false
            }
          },
        })
      }

      if (question.isImages) {
        for (const answer of question.answers.filter(a => a.imageFile)) {
          tasks.push({
            title: `Загрузка изображения ${answer.imageFile?.name}`,
            action: async () => {
              try {
                const formData = new FormData()
                formData.append('image', answer.imageFile!)
                formData.append('folder', 'lessons/tests')

                const response: AxiosResponse<{ url: string }> =
                  await axios.post('v2/uploads/images', formData, {
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    },
                  })

                answer.imageFile = null
                answer.image.set(response.data.url)
                return true
              } catch (error) {
                console.error(error)
                showError('При загрузке изображения произошла ошибка')
                return false
              }
            },
          })
        }
      }
    }

    if (lessonStore.lessonState === 'new') {
      tasks.push({
        title: 'Создание урока',
        action: lessonStore.createLesson,
      })
    } else {
      tasks.push({
        title: 'Сохранение урока',
        action: lessonStore.saveLesson,
      })
    }

    return tasks
  }

  protected render() {
    return (
      <LoaderOverlay ref="overlay">
        <div class="d-flex mb-1">
          <BButton
            onClick={() => this.saveLesson(false)}
            variant="primary"
            class="mr-1"
          >
            {lessonStore.lessonState === 'new'
              ? 'Создать занятие'
              : 'Сохранить занятие'}
          </BButton>

          <BButton onClick={() => this.saveLesson()} variant="outline-primary">
            {lessonStore.lessonState === 'new'
              ? 'Создать и закрыть'
              : 'Сохранить и закрыть'}
          </BButton>
          <div class="flex-grow-1" />
          <BButton
            onClick={this.copy}
            v-b-tooltip
            title="Копировать"
            variant="primary"
            class="mx-50 px-1"
          >
            <feather-icon icon="CopyIcon" />
          </BButton>
          <BButton
            onClick={this.paste}
            v-b-tooltip
            title="Вставить"
            variant="outline-primary"
            class="px-1"
          >
            <feather-icon icon="ClipboardIcon" />
          </BButton>
        </div>
        <BTabs>
          <BTab
            title="Основные данные"
            title-link-class={
              lessonStore.mainValidator.hasErrors ? 'text-danger' : null
            }
          >
            <MainTab />
          </BTab>
          <BTab
            title="Файлы и примечания"
            title-link-class={
              lessonStore.filesAndNotesValidator.hasErrors
                ? 'text-danger'
                : null
            }
          >
            <FilesTab />
          </BTab>
          <BTab
            title="Домашнее задание"
            disabled={this.homeworkType !== 'work'}
            title-link-class={
              lessonStore.homeworkValidator.hasErrors ? 'text-danger' : null
            }
          >
            <HomeworkTab />
          </BTab>
          <BTab
            title="Тестирование"
            disabled={this.homeworkType !== 'test'}
            title-link-class={
              lessonStore.testValidator.hasErrors ? 'text-danger' : null
            }
          >
            <TestTab />
          </BTab>
        </BTabs>
      </LoaderOverlay>
    )
  }
}
