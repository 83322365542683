import { BButton, BCard, BCardTitle, BCol, BRow } from '@/components/bootstrap'
import FormInput from '@/components/controls/form/FormInput'
import { lessonStore } from '@/store'
import { Component } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import Draggable from '@/components/Draggable'
import Question from './Question'
import { copyTextToClipboard } from '@/helpers/copy'
import { showMessage } from '@/helpers/notifications'
import './style.scoped.scss'

@Component
export default class TestTab extends tsx.Component<{}> {
  private async copy() {
    copyTextToClipboard(JSON.stringify(await lessonStore.getTestData()))
    showMessage('Скопировано!')
  }

  private async paste() {
    lessonStore.pasteTestData(await navigator.clipboard.readText())
  }

  protected render() {
    return (
      <BCard>
        <div class="d-flex align-items-center mb-2">
          <BCardTitle class="flex-grow-1 m-0">Баллы за тестирование</BCardTitle>
          <BButton
            onClick={this.copy}
            v-b-tooltip
            title="Копировать"
            variant="primary"
            class="mx-50 px-1"
          >
            <feather-icon icon="CopyIcon" />
          </BButton>
          <BButton
            onClick={this.paste}
            v-b-tooltip
            title="Вставить"
            variant="outline-primary"
            class="px-1"
          >
            <feather-icon icon="ClipboardIcon" />
          </BButton>
        </div>
        <BRow>
          <BCol sm={4}>
            <FormInput
              fieldValidator={lessonStore.testBadPoints}
              label="плохое прохождение (меньше 75%)"
              type="number"
            />
          </BCol>
          <BCol sm={4}>
            <FormInput
              fieldValidator={lessonStore.testGoodPoints}
              label="хорошее прохождение (75% - 89%)"
              type="number"
            />
          </BCol>
          <BCol sm={4}>
            <FormInput
              fieldValidator={lessonStore.testGreatPoints}
              label="отличное прохождение (90% и больше)"
              type="number"
            />
          </BCol>
        </BRow>
        <BCardTitle>Редактирование теста</BCardTitle>

        <Draggable
          value={lessonStore.testQuestions}
          onInput={value => (lessonStore.testQuestions = value)}
          scopedSlots={{
            item: ({ item, index }) => (
              <Question question={item} index={index + 1} key={item.id} />
            ),
          }}
        />

        <BButton
          variant="outline-primary"
          onClick={() => lessonStore.addQuestion()}
        >
          Добавить вопрос
        </BButton>
        <BButton
          variant="primary"
          class="collapse-btn"
          onClick={() =>
            lessonStore.testQuestions.forEach(q => (q.collapsed = true))
          }
        >
          Свернуть всё
        </BButton>
      </BCard>
    )
  }
}
