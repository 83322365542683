import { BButton } from '@/components/bootstrap'
import FormInput from '@/components/controls/form/FormInput'
import { lessonStore } from '@/store'
import { LessonLink } from '@/store/lessonStore'
import { Component, Prop } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'

@Component
export default class LinkView extends tsx.Component<{
  link: LessonLink
}> {
  @Prop({ type: Object, required: true })
  readonly link!: LessonLink

  remove() {
    lessonStore.removeLink(this.link)
  }

  protected render() {
    return (
      <div class="border rounded p-50 d-flex mb-50">
        <div class="flex-grow-1 mr-50">
          <FormInput
            fieldValidator={this.link.url}
            placeholder="Ссылка"
            class="mb-25"
          />
          <FormInput
            fieldValidator={this.link.name}
            placeholder="Текст ссылки"
            class="m-0"
          />
        </div>
        <BButton onClick={this.remove} variant="outline-danger">
          <feather-icon icon="TrashIcon" />
        </BButton>
      </div>
    )
  }
}
